import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonBackButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import { CircularProgress, Stack } from "@mui/material";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useSkillsAssessmentFeatureFlags } from "@src/appV2/SkillsAssessment/api/useSkillsAssessmentFeatureFlags";
import {
  AssessmentQualification,
  useSkillsAssessments,
} from "@src/appV2/SkillsAssessment/api/useSkillsAssessments";
import { useSubmitAssessment } from "@src/appV2/SkillsAssessment/api/useSubmitAssessment";
import { ExitRecordingConfirmationDialog } from "@src/appV2/SkillsAssessment/components/ExitRecordingConfirmationDialog";
import {
  SkillsAssessmentVideoFlow,
  SkillsAssessmentVideoFlowStep,
} from "@src/appV2/SkillsAssessment/components/VideoFlow/VideoFlow";
import {
  SkillsAssessmentStatus,
  SkillsAssessmentWorkerStatus,
} from "@src/appV2/SkillsAssessment/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { StyledIonPage } from "./style";
import { STAGES } from "../model";

export function SkillsAssessmentOnboarding() {
  const worker = useDefinedWorker();
  const history = useHistory();

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_ONBOARDING_PAGE_VIEWED);

  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const {
    data: assessments,
    isLoading,
    isSuccess,
    refetch,
  } = useSkillsAssessments(
    {
      workerId: worker.userId,
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
    },
    { enabled: isDefined(worker.preference.qualification), staleTime: 0, cacheTime: 0 }
  );
  const { mutateAsync: submitAssessment } = useSubmitAssessment();
  const assessmentQualification = assessments?.included.find(
    (included) => included.type === "qualification"
  ) as AssessmentQualification;
  const assessmentStatus = assessments?.included.find(
    (included) => included.type === "assessment-status"
  ) as SkillsAssessmentStatus;

  const confirmExitDialogState = useModalState();

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT}
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <Stack alignItems="center" width="100%" height="100%" padding={2}>
        {isLoading && <CircularProgress />}
        {isSuccess && isDefined(assessmentStatus) && (
          <>
            <SkillsAssessmentVideoFlow
              initialStep={
                assessmentStatus.attributes.status === SkillsAssessmentWorkerStatus.PENDING
                  ? SkillsAssessmentVideoFlowStep.START_FROM_ONBOARDING
                  : SkillsAssessmentVideoFlowStep.ALREADY_COMPLETED_FROM_ONBOARDING
              }
              endStep={SkillsAssessmentVideoFlowStep.END_FROM_ONBOARDING}
              assessment={assessments.data[0]}
              assessmentQualification={assessmentQualification?.attributes.name}
              showSkipButton
              onSkip={confirmExitDialogState.openModal}
              onAssessmentCompleted={() => {
                void submitAssessment({
                  assessmentId: assessments.data[0].id,
                  workerId: worker.userId,
                });
              }}
              onFinish={() => {
                history.push(STAGES.contractorAgreement.nextStagePath);
                // refetching to make sure that the data is updated immediately and the worker can't retake the assessment
                void refetch();
              }}
            />
            <ExitRecordingConfirmationDialog
              modalState={confirmExitDialogState}
              assessment={assessments.data[0]}
              onConfirm={() => {
                history.push(STAGES.contractorAgreement.nextStagePath);
                logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_ONBOARDING_SKIPPED, {
                  assessment: assessments.data[0],
                });
                confirmExitDialogState.closeModal();
              }}
            />
          </>
        )}
      </Stack>
    </StyledIonPage>
  );
}
